import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import career from "../../../career.json"

export default () => {
  const activeJobs = career.career.filter(
    job => job.status.toLowerCase() === "active"
  )
  return (
    <Layout>
      <SEO
        title="Careers"
        description="Join our team at D2i Technology. We are hiring for various software engineering positions including Salesforce Developer, Lead Golang Developer, Business Developer, and Automation Engineer."
        keywords={['Software Engineer Jobs', 'IT Job Openings', 'Tech Careers', 'Software Development Jobs', 'Hiring Now in IT']}
      />
      <main id="main">
        <section id="careers" className="careers team section-bg">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h1>Careers at D2i Technology</h1>
              <p>Join Our Team and Shape the Future of Technology</p>
            </header>

            <div className="row text-center">
              <div className="col-lg-12 mx-auto">
                <p>
                  At D2i Technology, we believe that innovation thrives in an
                  environment where passionate and talented individuals come
                  together to create cutting-edge solutions. We are a dynamic
                  and forward-thinking IT services company, committed to
                  excellence and driven by a shared vision of delivering
                  exceptional value to our clients.
                </p>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12 mx-auto">
                <h2 className="text-center mb-4 pb-4">Why Work with Us?</h2>
                <div className="row justify-content-center">
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="why-us-item text-center">
                      <span className="why-us-icon" aria-hidden="true">
                        💡
                      </span>
                      <h3>Innovative Projects</h3>
                      <p>
                        Work on challenging and impactful projects that push the
                        boundaries of technology.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="why-us-item text-center">
                      <span className="why-us-icon" aria-hidden="true">
                        👥
                      </span>
                      <h3>Collaborative Environment</h3>
                      <p>
                        Join a team of enthusiastic professionals who are eager
                        to share knowledge and collaborate.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="why-us-item text-center">
                      <span className="why-us-icon" aria-hidden="true">
                        📈
                      </span>
                      <h3>Career Growth</h3>
                      <p>
                        Benefit from continuous learning opportunities and a
                        clear path for career advancement.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="why-us-item text-center">
                      <span className="why-us-icon" aria-hidden="true">
                        🌍
                      </span>
                      <h3>Diverse Culture</h3>
                      <p>
                        Be part of a diverse and inclusive workplace that values
                        different perspectives and experiences.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <div className="why-us-item text-center">
                      <span className="why-us-icon" aria-hidden="true">
                        ⚖️
                      </span>
                      <h3>Work-Life Balance</h3>
                      <p>
                        Enjoy a flexible work environment that supports a
                        healthy work-life balance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 text-center">
              <div className="col-12">
                <h2>Current Openings</h2>
                <p>
                  We are hiring for Software Engineer for the following
                  profiles:
                </p>
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              {activeJobs.map(job => (
                <div
                  key={job.id}
                  className="col-lg-5 col-md-6 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay={100 * job.id}
                >
                  {job.url ? (
                    <Link to={`/${job.url}`} className="member">
                      <div className="member-info">
                        <h3>{job.title}</h3>
                        <p className="mb-0">{job.summary}</p>
                      </div>
                    </Link>
                  ) : (
                    <div className="member">
                      <div className="member-info">
                        <h3>{job.title}</h3>
                        <p className="mb-0">{job.summary}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="row mt-5 text-center">
              <div className="col-lg-12 mx-auto">
                <h2>Join Us Today</h2>
                <p>
                  Be part of a company that values your skills and fosters your
                  growth. Join D2i Technology and let's shape the future of
                  technology together!
                </p>
              </div>
            </div>

            <div className="row content">
              <div className="col-12 pt-4 text-center">
                <a
                  href="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  APPLY NOW
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
